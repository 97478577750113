exports.components = {
  "component---src-pages-appcreation-js": () => import("./../../../src/pages/appcreation.js" /* webpackChunkName: "component---src-pages-appcreation-js" */),
  "component---src-pages-auth-callback-js": () => import("./../../../src/pages/auth_callback.js" /* webpackChunkName: "component---src-pages-auth-callback-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-getting-started-js": () => import("./../../../src/pages/getting-started.js" /* webpackChunkName: "component---src-pages-getting-started-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loginpage-js": () => import("./../../../src/pages/loginpage.js" /* webpackChunkName: "component---src-pages-loginpage-js" */),
  "component---src-pages-my-swagger-js": () => import("./../../../src/pages/my_swagger.js" /* webpackChunkName: "component---src-pages-my-swagger-js" */),
  "component---src-pages-openapi-js": () => import("./../../../src/pages/openapi.js" /* webpackChunkName: "component---src-pages-openapi-js" */),
  "component---src-pages-redirect-to-account-js": () => import("./../../../src/pages/redirect_to_account.js" /* webpackChunkName: "component---src-pages-redirect-to-account-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */)
}

