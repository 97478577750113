import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './components/layout.css';
import { GlobalStyle } from './globalStyle';
//import AlertMessage from './components/Shared/AlertMessage';
import {MessageContext} from './utils/context';

const Layout = ({children}) => {
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
    
  const createMessage = (message, type) => {
    setMessage(message);
    setMessageType(type);
  };

  // message shows on the top of the page for 5 sec.
  useEffect(() => {
    if(message != null && messageType != null) {
      setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 5000);
    }
  }, [message, messageType]);

  return (
    <MessageContext.Provider 
      value={{
        message: message,
        type: messageType,
        createMessage: createMessage}}
    >
      <GlobalStyle/>
      
      <main>{children}</main>
    </MessageContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
