import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Backend from 'i18next-http-backend'; // new
i18n

  .use(Backend)  // new
  .use(LanguageDetector) // new
  .use(initReactI18next)
  .init({
    resources: { // Import translations you made from certain path.
      en: {
        translation: require('./locales/en/translation.json')
      },
      es: {
        translation: require('./locales/es/translation.json')
      },
      fi: {
        translation: require('./locales/fi/translation.json')
      },
      de: {
        translation: require('./locales/de/translation.json')
      },
      fr: {
        translation: require('./locales/fr/translation.json')
      },
      ja: {
        translation: require('./locales/ja/translation.json')
      }
    },
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;