import { createGlobalStyle } from 'styled-components';
import './assets/fonts/fonts.css';

const GlobalStyle = createGlobalStyle`
a {
	text-decoration: none;
  :focus {
    outline: none;
  }
}
p a:focus {
  outline: none;
  color: #ffb600;
  border-color: #ffb600;
}

svg {
  display: block;
}

.border-box {
  box-sizing: border-box;
}

body {
	height: 100%;
	width: 100%;
}

.heading-1 {
	font-family: 'Scout Thi', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
	font-weight: 400;
  font-size: 38px;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.01em;
	@media only screen and (min-width: 640px){
		font-size: 48px;
	}
	@media only screen and (min-width: 768px){
    font-size: 58px;
	}
}

.heading-2 {
	font-family: 'Scout Thi', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
	font-weight: 400;
  font-size: 31px;
  line-height: 1;
	@media only screen and (min-width: 640px){
		font-size: 43px;
	}
	@media only screen and (min-width: 768px){
    font-size: 55px;
	}
}

.heading-3 {
	font-family: 'Scout Thi', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
	font-weight: 400;
  font-size: 28px;
  line-height: 1;
  letter-spacing: 0.01em;
	@media only screen and (min-width: 640px){
		font-size: 38px;
	}
	@media only screen and (min-width: 768px){
    font-size: 48px;
	}
}

.default-text, p {
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
	font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.2px;
}

.medium-text {
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
	font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.2px;
}

.extra-small-text {
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
	font-weight: 400;
  font-size: 10px;
  line-height: 1.4%;
  letter-spacing: 0.2px;
}

.label-text {
  font-family: 'Scout Reg','Roboto','Helvetica','Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.text-white {
  color: #ffffff;
}

.text-center {
  text-align: center;
}

.heading-4 {
	font-family: 'Scout Lt', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 100%;
  margin: 0;
}

.heading-5 {
	font-family: 'Scout Reg', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  margin: 0;
  margin-bottom: 15px;
}

.leading-paragraph {
	font-family: 'Roboto Light', 'Helvetica', 'Arial', sans-serif;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.default-text, p {
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
	font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  margin: 0;
}

.medium-text {
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
	font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.2px;
}

.extra-small-text {
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
	font-weight: 400;
  font-size: 10px;
  line-height: 1.4%;
  letter-spacing: 0.2px;
}

.label-text {
  font-family: 'Scout Reg','Roboto','Helvetica','Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.text-white {
  color: #ffffff;
}

.text-center {
  text-align: center;
}

.slick-prev:before, .slick-next:before {
    color: #3595F2  !important;
}

.form-input {
	appearance: none;
	display: block;
	box-shadow: none;
	outline: none;
  height: 53px;
  background: #FFFFFF;
  border: 1px solid #DCDEE1;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 12px;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
	font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #2D303E;
  .error & {
    border-color: #FF4964;
  }
}

.error-message {
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 0;
  color: #FF4964;
  padding-right: 16px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

label, a, button {
  &:focus {
    outline: 0;
  }
  &:focus-visible {
    outline: 1px solid #017AF5 !important;
    outline-offset: 4px;
  }
}
`;

export { GlobalStyle };
