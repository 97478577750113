import {createContext} from 'react';


export const MessageContext = createContext({
  message: null,
  type: null,
  createMessage: () => {}
});

// export const UserInfoContext = createContext({
//  userinfo: null,
//  createUserInfo: () => {}
// });
